@import '~@styles/variables.scss';

ul.steps {
  display: inline-block;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  .badge {
    position: absolute;
    top: -6px;
    right: 15px;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: red;
    color: white;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }

  li {
    position: relative;
    float: left;
    width: 50px;
    height: 4px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: #e0e0e0;
    list-style: none;

    &.active {
      background-color: #616161;
    }
  }
}

.person-competence-form {
  display: flex;
  flex-direction: column;

  @include md() {
    min-height: 450px;
  }

  .selected-courses {
    width: 100%;
    margin-bottom: 10px;

    font-size: 14px;

    .course {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: row;

      .status {
        display: flex;
        align-items: center;
        margin-right: 10px;
        justify-items: center;
      }

      .txt {
        flex: 2;
        padding: 10px;

      }

      .action-btn {
        justify-content: flex-start;
        align-content: flex-end;
        align-items: flex-start;
        align-self: flex-start;
        justify-items: flex-start;
        justify-self: flex-end;
      }
    }
  }

  .form {
    clear: both;
    width: 100%;

    .two {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include md() {
        flex-direction: row;

        > * {
          flex: 1;
        }
      }
    }

    .row-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 20px;
      justify-items: center;
    }
  }

  .form-header {
    h2 {
      margin-bottom: -10px;
    }

    p {
      margin-bottom: 20px;
      color: #666;
      font-size: 14px;
    }

    input {
      margin-right: 4px;
    }

    label {
      padding-right: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    h4 {
      display: block;
      margin-bottom: -10px;

      @include md() {
        display: none;
      }
    }

    @include md() {
      flex-direction: row;
    }

    &__left {
      overflow: auto;
      flex: 1;
      width: 60%;
      max-height: 180px;
      border: 1px solid #e8e8e8;
      padding: 20px;

      @include md() {
        max-height: 400px;
        margin-right: 40px;
      }

      h3 {
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        font-weight: 500;
        font-size: 1.1em;
      }
    }

    &__right {
      overflow: auto;
      flex: 1;
      max-height: 200px;
      margin-top: 20px;
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      padding: 20px;

      h3 {
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        font-weight: 500;
        font-size: 1.1em;
      }

      @include md() {
        max-height: 400px;
        margin-top: 0;
        margin-right: 40px;
      }
    }
  }

  .course-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;

    .check {
      input {
        margin-right: 10px;
        padding: 2px;
      }
    }

    .txt {

    }
  }

  &__category-button {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-left: 15px;
    text-align: left;
    cursor: pointer;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__section-wrapper {
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
    width: 100%;
    min-height: 80px;
    max-height: 60vh;
    margin: 0;
    padding-right: 20px;
  }

  &__section {
    margin-top: 10px;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-weight: 600;
    font-size: $font-size-m;

    &.active {
      background-color: #efefef;

      .person-competence-form__category-button {
        font-weight: 900;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: center;
  }
}

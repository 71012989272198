@import '~@styles/variables.scss';

.employee-list-header {
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 40px !important;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;
  .wrapper{
    max-width: 1280px;
    margin: 0 auto;
      margin-top: 1.5em;
    @include lg(){
       width: 80%;
       margin-top: 0;
    }
  }
  .breadcrumps {
    max-width: 1280px;
  }
}

.employees-list-container {
  flex: 1 1 auto;

  .employees-list {

    margin-top: 2em;
    @include sm() {
      margin-top: 4em;
    }
  }

  &__expired-elements {
    width: 100%;
    margin-bottom: 3em;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 3.2em;
  }

  &__course-elements {
    width: 100%;
    margin-bottom: 3em;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 2em;
  }

  &__search-and-actions-wrapper{
    width: 100%;

    .employees-list-container__search-bar-outer-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
    }

    @media (max-width: $tablet-width){
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      width: 85%;
      
      .search-bar{
        padding-right: 0;
        margin-bottom: 0.9em;
      }
    }
  }


  &__search-bar-wrapper {
    flex: 1 1 auto;
    width: 100%;
  }

  &__actions-container {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 10px;
  }

  &__add-employee-button {
    border: none;
    padding: 10px;
    background: none;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__add-employee-button-icon {
    margin-right: 5px;
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }
}

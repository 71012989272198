@import '~@styles/variables.scss';

.employees-link-card {
  $this: &;

  display: flex;
  flex-grow: 1;
  flex-direction: row;
  box-shadow: $card-shadow;
  border-radius: 5px;
  padding: 15px;
  background: var(--color-main-dark);
  line-height: $font-size-l;

  &__label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-width: 120px;
    padding: 0 10px;
    color: white;
    font-weight: 700;
    font-size: $font-size-m;
  }

  &--light {
    border: 1px solid var(--color-main-dark);
    background: #efefef;

    #{$this} {
      &__label {
        color: #444;
      }
    }
  }

  a {
    display: flex;
    flex-grow: 1;

    &:active {
      text-decoration: unset;
    }
  }

  &__count {
    color: white;
    font-weight: 700;
    font-size: $font-size-l;
  }
}

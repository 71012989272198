@import '~@styles/variables.scss';

.progress {
  width: 100%;
  color: $color-grey-50;
  font-weight: 600;
  font-size: $font-size-sm;
  line-height: 24px;
  white-space: nowrap;

  &__progress {
    display: inline-block;
    margin-right: 12px;
    text-indent: 6px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
  }

  &__progress-big {
    color: #0a0a0a;
    font-weight: normal;
    font-size: $font-size-ss;

    &__bar {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 100%;
      border-radius: 8px;
      background-color: #eaeaea;
    }

    .txt {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 16px;
      color: #0a0a0a;
      font-size: $font-size-ss;
    }
  }

  &__label {
    display: inline-block;
    font-weight: 500;
    font-size: $font-size-s;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
  }

  &__bar-overall {
    max-width: 300px;
    width: 100%;
    height: 7px;
    margin-top: 2px;
    justify-self: center;
    align-self: center;
    border-radius: 5px;
    background-color: #e0e0e0;

    &--full-width {
      width: 100%;
      max-width: 100%;
    }
  }

  &__bar-complete {
    height: 7px;
    border-radius: 5px;
    background-color: $color-progressbar-fill;
  }
}

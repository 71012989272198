@import '~@styles/variables.scss';

$modal-loading-text-color: #a1a1a1;
$modal-loading-text-size: 3em;

.modal {
  $this: &;

  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;

  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 20px;

  &__backdrop {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    position: fixed;
    height: auto;

    @include md {
      top: 10%;
    }

    @include sm {
      top: 20%;
      left: 50%;
    }

    z-index: 1;

    /*
      overflow: visible;
      overflow: hidden;
      overflow-x: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-y: visible;
     */

    flex: 0 0 auto;
    width: 100%;
    height: fit-content;
    height: auto;
    max-height: 100%;
    margin: auto;
    margin-bottom: 40px;
    border-radius: 10px;
    border-radius: 8px;
    transform: translate(0%, 0%);
    top: 20px;
    @include sm {
      transform: translate(-50%, 0%);
    }

    @include sm() {
      width: auto;
    }
  }

  &__loading-text {
    /* Absolute center */
    position: fixed;
    top: 50%;
    left: 50%;
    color: $modal-loading-text-color;
    font-size: $modal-loading-text-size;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
  }

  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    &__content {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $tablet-width) {
    padding: 0;

    &__content {
      width: 100%;
      margin-top: 30px;
    }
  }
}

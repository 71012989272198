@import '~@styles/variables.scss';

.statistic-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E0E0E0;
  margin: 15px;
  border-radius: 5px;
  background: $color-white;

  &__inner {
    flex: 1 1 auto;
    padding: 35px 8px;
  }
  &__link {
    height: 40px;
    width: 100%;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #0B5CD7;
    align-content: center;
    align-items: center;
    padding-left: 20px;
    color: white;
    font-weight:600;
    display: flex;
    svg {
      position: absolute;
      right: 20px;
      font-size: 20px;
    }
  }
  &-clickable {
    &:hover {
      background: $color-grey-99;
    }
  }

  &-mobile {
    flex: 1 1 100%;
  }

  &__icon-wrapper {
    position: relative;
    display: flex;
    align-self: stretch;
    width: 130px;
    height: 130px;
    margin: auto;
    margin-bottom: 0;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle-wrapper {
    position: relative;
    display: flex;
    align-self: stretch;
    width: 130px;
    height: 130px;
    margin: auto;
    margin-bottom: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-grey-50;
    font-weight: 700;
    font-size: $font-size-sm;
  }

  &__title {
    margin: auto;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: $font-size-m;
    color: black;
    line-height: 1.5;
    text-align: center;
  }
}

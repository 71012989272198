@import '~@styles/variables.scss';

$color-menu-search-background: var(--color-main-dark-2);

.mobile-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &__header {
    z-index: 4000;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: var(--color-header-top);
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }

  &__logo {
    width: 70px;
    margin-top: 4px;
    fill: #fff;

    &.theme10,
    &.theme11,
    &.theme12,
    &.theme14,
    &.theme16,
    &.theme17,
    &.theme19 {
      width: 50px;
      margin-top: 0;
    }
  }

  &__bottom {
    display: block;
    width: 100%;
    height: 50px;
    background: var(--color-header-bottom);
  }

  &__profile {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__progress {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-right: 70px;
    color: var(--color-header-text);
    font-size: $font-size-l;

    .progress__bar-complete {
      background-color: $color-progressbar-fill-2;
    }
  }

  &__avatar {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-top: 5px;
    margin-left: 16px;
    cursor: pointer;

    &-image {
      width: 100%;
      height: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 100%;
      background-position: center;
      background-size: cover;
    }
  }

  &__toggle-sidebar-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
  }

  &__toggle-sidebar-button {
    display: inline-block;
    box-shadow: none;
    margin: 5px 10px;
    border: none;
    padding: 7px 15px;
    background: none;
    color: var(--color-header-text);
    font-size: 24px;
    line-height: 1;

    &:active {
      border: none;
      background: rgba(116, 116, 116, 0.3);
    }
  }

  &__sidebar {
    position: fixed;
    top: 40px;
    right: 0;
    z-index: 3000;
    width: 0;
    height: 100%;
    background: rgba(116, 116, 116, 0);
    transition: background 0.3s ease-in;

    &--opened {
      width: 100%;
      background: rgba(116, 116, 116, 0.5);
    }
  }

  &__sidebar-content {
    position: absolute;
    top: -100vh;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--color-header-bottom);
    transition: top 0.3s linear;

    &--opened {
      top: 0;
    }
  }

  &__search-wrapper {
    display: flex;
    margin: 15px;
  }

  &__search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
  }

  &__search-input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 3px;
    padding: 0 3em 0 0.75em;
    background: $color-menu-search-background;
    color: var(--color-input-text);
    font-size: $font-size-sm;
    line-height: 1;

    &::placeholder {
      color: var(--color-input-text);
    }
  }

  &__search-icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: var(--color-input-text);
    font-size: $font-size-m;
  }

  &__sidebar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  &__sidebar-link {
    display: inline-block;
    margin: 0 15px;
    padding: 0 7px;
    color: var(--color-header-text) !important;
    font-size: $font-size-l;
    line-height: 1.8;
    text-decoration: none;

    &--active {
      color: var(--color-header-text) !important;
      font-weight: 700;
    }

    &-child {
      margin-left: 40px;
      font-size: 1.1rem;
    }
  }

  &__sidebar-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }

  &__sidebar-button {
    display: inline-block;
    margin: 0 15px;
    border: none;
    padding: 0 7px;
    background: none;
    color: var(--color-header-text);
    font-weight: 400;
    font-size: $font-size-ml;
    line-height: 1.8;

    i {
      opacity: 0.8;
      margin-right: 7px;
    }

    &,
    &:hover,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }

  &__sidebar-close-button {
    $delete-size: 40px;
    $x-width: 3px;

    position: absolute;
    top: 20px;
    left: 20px;
    width: $delete-size;
    height: $delete-size;
    box-shadow: 1px 1px 4px rgba(116, 116, 116, 0.3);
    outline: none;
    border: none;
    border-radius: $delete-size;
    padding: 0;
    background: rgba(116, 116, 116, 0.3);
    color: white;
    line-height: $delete-size;
    text-align: center;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: ($delete-size / 2) - ($x-width / 2);
      left: (0.35 * $delete-size) / 2;
      width: 0.65 * $delete-size;
      height: $x-width;
      background-color: white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

@import '~@styles/variables.scss';

.employees-report {
  font-family: $fonts;

  &__buttons {
    margin-top: 20px;
    padding-left: 10px;
    color: $color-black;
    font-size: $font-size-sm;
    line-height: 1.2em;
    text-transform: uppercase;

    a {
      color: var(--color-main-dark);

      &:active,
      &:visited,
      &:hover {
        color: var(--color-main-dark);
      }

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      color: $color-black;
      font-size: 1.2em !important;
    }
  }

  .courseReport {
    margin-right: 50px;
    padding-right: 50px;

    #ts_tbl {
      margin-bottom: 30px;
      margin-left: 10px;
      border-spacing: 0;
      border-collapse: collapse;

      th {
        position: relative;
        overflow: hidden;
        width: 25px;
        height: 300px;
        padding-bottom: 10px;
        vertical-align: bottom;
      }

      td {
        text-align: center;

        h3 {
          font-weight: 800;
          font-size: 1.3em;
        }
      }

      .col_name {
        width: 300px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        padding: 0 5px 0 5px;
        padding: 4px;
        background: #eeebe6;
        font-size: 0.9em;
        text-align: left;
      }

      .col_position {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        padding: 0 5px 0 5px;
        background: #eeebe6;
        font-weight: normal;
        font-size: 0.9em;
        text-align: left;
      }

      td.course {
        width: 40px;
        padding: 3px;
        font-size: 12px;
      }

      .legendcell {
        font-size: 12px;
        text-align: left;
      }
    }

    .box_rotate {
      font-weight: bold;
    }

    .box_rotate_col {
      overflow: visible;
      display: block;
      width: 30px;
      padding-top: 0;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }

    .mandatory {
      background-color: rgb(220, 78, 65);
    }

    .mandatory.ok {
      background-color: #5cb85c;
    }

    .mandatory.not_due {
      background-color: yellow;
    }

    .voluntary {
      border: 1px solid black;
      color: transparent;
    }

    .legendcell .buttonset {
      float: left;
      clear: both;
      width: 100%;
      margin: 3px 0;
    }

    .legendcell .buttonset label {
      width: auto;
    }

    .legendcell .line {
      width: 90%;
      margin: 2%;
      margin-left: 2%;
      margin-left: 4%;
      border-bottom: 1px solid #cecece;
      padding: 3%;
      padding-bottom: 10px;
    }

    .legendcell .line .label {
      width: 210px;
      padding-top: 2px;
    }

    .legendcell .mandatory,
    #coursereport .legendcell .voluntary {
      float: right;
      width: 24px;
      height: 24px;
      border: 1px solid #909090;
      padding: 2px;
    }
  }

  #coursematrix {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    margin: 10px 0;
    padding-bottom: 39px;
  }

}

@import '~@styles/variables.scss';

.competence-card {
  box-shadow: $card-shadow;
  margin: 10px;
  border: 1px solid $color-grey-85;
  border-radius: 8px;
  padding: 20px;
  background: white;

  @media screen and (max-width: $tablet-width) {
    margin: 10px 0;
  }

  &.compact {
    position: relative;
    box-shadow: none;
    margin: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #d8d8d8;
    padding: 10px;

    .passed-ico {
      top: 20px;
      right: 40px;
      width: 16px;
      margin-top: 0;
    }

    .shrink.button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .course-preview__action-button {
      box-shadow: none;
      border: 1px solid #ccc;
      background-color: #efefef;
      color: #666;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &.employee {
    border: none;

    .grid-x {
      width: 100%;

      .course-grade {
        font-size: $font-size-s;
        text-align: right;
      }
    }
  }

  .sub {
    h3 {
      border-bottom: 1px solid #ccc;
      font-size: $font-size-sm;
    }
  }

  .button {
    text-align: right;
  }

  .competence-card-sub {
    margin: 4px 0;
    padding: 4px;
    font-size: $font-size-sm;
  }

  .small-header {
    width: 100%;
    font-size: $font-size-s;
  }

  .preview-files {

    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    a {
      color: white!important;
    }
    .btn {
      margin-right: 5px;
    }
  }
}

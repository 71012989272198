@import '~@styles/variables.scss';

.signature-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  padding: 15px;
  background: white;

  &__header {
    margin: 5px 0;
    padding-bottom: 20px;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 40px;
    padding-right: 5px;
    text-align: right;
  }
}

@import '~@styles/variables.scss';


.events-list {
  flex: 1 1 auto;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    border: 1px solid $event-border;
    border-radius: 10px;
    padding: 10px;
    color: $event-default-text-color;

    &.has-expired{
      background-color: $event-default-background;
    }
    &.expire-soon{
      background-color: #D16603;
    }
    em {
      font-size: 0.8em;
    }
    a {
      color: $event-default-text-color;

      &.title{
        word-break: break-word;
        hyphens: auto;
        font-weight: 500;
        font-style: italic;
      }
    }

    &.event {
      background-color: $event-background;
      color: $event-text-color;
      a {
        color: $event-text-color;
      }
    }
    &.expire {
      color: black;
      border: 0;
      border-left: 4px solid #EA780E;
      border-radius: 4px;
      padding: 0;
      background-color: #FFF2E7;
      a {
        color: black;
      }
      .events-list__card__contents {
        flex-direction: column;
        align-items: flex-start;
      }

      .events-list__card {
        border: 0;
        flex-direction: row;
      }
      em {
        color: #EA780E;
      }
      .events-list__text__description {
        color: black;
        justify-self: flex-end;
      }
      .events-list__date {
        margin: 10px;
        background-color: white;
      }
    }
    &.expired {
      color: black;
      border: 0;
      border-left: 4px solid #E00000;
      border-radius: 4px;
      padding: 0;
      background-color: #FFECEC;
      a {
        color: black;
      }
      .events-list__card__contents {
        flex-direction: column;
        align-items: flex-start;
      }

      .events-list__card {
        border: 0;
        flex-direction: row;
      }
      em {
        color: #E00000;
      }
      .events-list__text__description {
        color: black;
        justify-self: flex-end;
      }
      .events-list__date {
        margin: 10px;
        background-color: white;
      }
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    min-width: 50px;
    height: 100%;
    margin-right: 20px;
    line-height: 1.2;

    background-color: rgba(255,255,255,0.1);
    border-radius: 8px;
    padding: 0.6em 1em;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &__day {
      font-weight: 600;
      font-size: 1.2em;
    }

    &__month {
      font-size: $font-size-s;
      text-transform: lowercase;
    }
  }

  &__avatar {
    flex: 0 0 40px;
    margin-top: 5px;
    margin-right: 25px;
    margin-left: 10px;

    .person-avatar {
      width: 45px;
      height: 45px;
    }
  }

  &__card {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    border: 1px solid $event-border;
    padding: 0.9em;
    padding-right: 1.5em;

    > a {
      width: 100%;
      color: black;
    }

    &__contents {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    &__competence-info{
      display: flex;
    }

    &.first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &__text {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1000px;
      left: -1000px;
      display: block;
      border-top: $separator-border;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;

      &::after {
        border-top: none;
      }
    }

    &__time_and_location {
      display: flex;
    }

    &__title {
      color: $event-text-color;
      font-weight: 600;
      font-size: $font-size-sm;

      &:hover {
        color: var(--color-main-dark);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__description {
      font-size: $font-size-s;
      line-height: 1.6;
      color: $event-text-color;
    }

    &__location {
      font-size: $font-size-s;
    }
  }

  &__person-name {
    margin-bottom: 6px;
    color: $font-color;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

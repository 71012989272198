@import '~@styles/variables.scss';

$color-menu-search-background: var(--color-header-bottom);

.desktop-menu {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background: #f9f9f9;

  @include lg() {
    height: $menu-height;
  }

  @media only screen and (max-width: 750px) {
    justify-content: flex-start;
  }

  &__top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    background: var(--color-header-top);
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 36px;
  }

  &__logo {
    width: 94px;
    fill: #fff;

    &.theme11,
    &.theme12,
    &.theme14,
    &.theme17,
    &.theme18 {
      width: 74px;
    }

    &.theme10 {
      width: 80px;
    }
  }

  &__search-wrapper {
    display: flex;
    flex-grow: 7;
    flex-shrink: 1;
    justify-content: flex-start;
    margin-left: 15px;
  }

  &__search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    max-width: 400px;
  }

  &__search-input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 3px;
    padding: 0 3em 0 0.75em;
    background: $color-menu-search-background;
    color: var(--color-input-text);
    font-size: $font-size-sm;
    line-height: 1;

    &::placeholder {
      color: var(--color-input-text);
    }
  }

  &__search-icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 100%;
    margin-left: 10px;
    color: var(--color-input-text);
    font-size: $font-size-m;
  }

  &__buttons {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    margin-right: 10px;
  }

  &__button {
    display: inline-block;
    border: none;
    padding: 16px 8px;
    background: none;
    color: white;
    font-weight: 400;
    font-size: $font-size-ml;

    &,
    &:hover,
    &:visited {
      text-decoration: none;
    }

    i {
      opacity: 0.8;
      margin-right: 7px;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }

  &__bottom-section {
    display: flex;
    flex: 0 0 60px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid $color-grey-80;
    background: var(--color-header-bottom);
  }

  &__link {
    display: inline-block;
    height: 59px;
    margin: 0 15px;
    padding: 0 7px;
    color: var(--color-header-text) !important;
    font-size: $font-size-m;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &--active {
      border-bottom: 2px solid var(--color-header-link-underline);
    }
  }
}

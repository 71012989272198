@import '~@styles/variables.scss';

.person-search-add-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    width: 100%;
  }


  &__submit-button-wrapper {
    display: flex;
    margin-left: auto;
  }

  &__dropdown-wrapper {
    position: absolute;
    top: 100%;
    right: 10px;
    left: 10px;
    z-index: 9999;
    margin-top: -11px;
    padding: 0;

    .search-bar__dropdown {
      overflow-y: auto;
      max-height: 400px;
      box-shadow: none;
      border: 1px solid $color-gray-fields;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

@import '~@styles/variables.scss';

.employee-message {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $desktop-small-width) {
    width: 100%;
    margin: 30px 0;
  }
}

@import '~@styles/variables.scss';

.action-bar {
  position: relative;
  padding-left: 1em;

  .action-bar-items {
    position: absolute;
    top: 50px;
    z-index: 1;
    min-width: 100%;
    box-shadow: $card-small-shadow;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    background-color: $color-white;
    font-weight: 500;
    font-size: $font-size-sm;
    line-height: 2;

    .item {
      display: block;
      border-bottom: $separator-border;
      padding: 10px 20px;
      background-color: $color-white;
      color: $color-grey-20;
      cursor: pointer;

      &:hover {
        background-color: $color-grey-95;
      }

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  .action-bar-action {
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    padding: 8px 20px;
    background-color: $color-black;
    color: $color-white;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 2;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-20;
    }

    i {
      position: absolute;
      top: 50%;
      right: 25px;
      display: block;
      line-height: 0;
    }
  }
}
